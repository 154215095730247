// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input.numeric-input::-webkit-outer-spin-button,
input.numeric-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'].numeric-input {
  -moz-appearance: textfield;
}

.btn-group .btn.btn-primary.datetime-conversions {
  white-space: normal;
  padding: 0.35rem 0.3rem;
}

div.builders > .btn-group.row:first-child > .btn:first-child {
  border-radius: 0.25rem 0 0 0;
}

div.builders > .btn-group.row:last-of-type > .btn {
  border-top-width: 0;
}

div.builders > .btn-group.row:last-of-type > .btn:first-of-type {
  border-radius: 0 0 0 0.25rem;
}

div.builders > .btn-group.row:last-of-type > .btn:last-of-type {
  border-radius: 0 0 0.25rem 0;
}

.first-type-group {
  padding-top: 0.25rem !important;
  border-top: 1px solid rgb(167, 179, 183);
}

.last-type-group {
  padding-bottom: 0.25rem !important;
  border-bottom: 1px solid rgb(167, 179, 183);
}

.exponential-smoothing {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  font-size: 95% !important;
  white-space: break-spaces !important;
  min-height: 33px;
  max-height: 33px;
}

.col-type {
  padding: 0.45rem 0.3rem;
}
`, "",{"version":3,"sources":["webpack://./static/popups/create/CreateColumn.css"],"names":[],"mappings":"AAAA;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA,YAAY;AACZ;EACE,0BAA0B;AAC5B;;AAEA;EACE,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,+BAA+B;EAC/B,wCAAwC;AAC1C;;AAEA;EACE,kCAAkC;EAClC,2CAA2C;AAC7C;;AAEA;EACE,2BAA2B;EAC3B,8BAA8B;EAC9B,yBAAyB;EACzB,oCAAoC;EACpC,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":["input.numeric-input::-webkit-outer-spin-button,\ninput.numeric-input::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n/* Firefox */\ninput[type='number'].numeric-input {\n  -moz-appearance: textfield;\n}\n\n.btn-group .btn.btn-primary.datetime-conversions {\n  white-space: normal;\n  padding: 0.35rem 0.3rem;\n}\n\ndiv.builders > .btn-group.row:first-child > .btn:first-child {\n  border-radius: 0.25rem 0 0 0;\n}\n\ndiv.builders > .btn-group.row:last-of-type > .btn {\n  border-top-width: 0;\n}\n\ndiv.builders > .btn-group.row:last-of-type > .btn:first-of-type {\n  border-radius: 0 0 0 0.25rem;\n}\n\ndiv.builders > .btn-group.row:last-of-type > .btn:last-of-type {\n  border-radius: 0 0 0.25rem 0;\n}\n\n.first-type-group {\n  padding-top: 0.25rem !important;\n  border-top: 1px solid rgb(167, 179, 183);\n}\n\n.last-type-group {\n  padding-bottom: 0.25rem !important;\n  border-bottom: 1px solid rgb(167, 179, 183);\n}\n\n.exponential-smoothing {\n  padding-top: 0px !important;\n  padding-bottom: 0px !important;\n  font-size: 95% !important;\n  white-space: break-spaces !important;\n  min-height: 33px;\n  max-height: 33px;\n}\n\n.col-type {\n  padding: 0.45rem 0.3rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
