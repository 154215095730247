// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.axis-toggle__dropdown {
  position: absolute;
  top: 100%;
  min-width: 15em;
  width: 100%;
  color: #404040;
  font-weight: 400;
  z-index: 10;
  margin-top: -1px;
  background-color: white;
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: #a7b3b7;
  border-top-color: #d3d9db;
  border-radius: 0 0 0.25rem 0.25rem;
}

.axis-toggle__dropdown ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.axis-toggle__dropdown li {
  display: flex;
  width: 100%;
}

.axis-toggle__dropdown li:last-child {
  border-bottom: solid 1px #a7b3b7;
}

.axis-toggle__dropdown li span {
  padding: 0.3em;
}

.axis-toggle__dropdown li span.toggler-action {
  border: none;
  margin-top: -0.1em;
}

.axis-toggle__dropdown li span.toggler-action button i {
  vertical-align: text-bottom;
}

.axis-input {
  text-align: center;
  width: 3em !important;
  padding: 0.3rem 0.3rem !important;
}
`, "",{"version":3,"sources":["webpack://./static/popups/charts/AxisEditor.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,SAAS;EACT,eAAe;EACf,WAAW;EACX,cAAc;EACd,gBAAgB;EAChB,WAAW;EACX,gBAAgB;EAChB,uBAAuB;EACvB,2BAA2B;EAC3B,mBAAmB;EACnB,qBAAqB;EACrB,yBAAyB;EACzB,kCAAkC;AACpC;;AAEA;EACE,SAAS;EACT,UAAU;EACV,gBAAgB;AAClB;;AAEA;EAGE,aAAa;EACb,WAAW;AACb;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,iCAAiC;AACnC","sourcesContent":[".axis-toggle__dropdown {\n  position: absolute;\n  top: 100%;\n  min-width: 15em;\n  width: 100%;\n  color: #404040;\n  font-weight: 400;\n  z-index: 10;\n  margin-top: -1px;\n  background-color: white;\n  border-width: 1px 1px 0 1px;\n  border-style: solid;\n  border-color: #a7b3b7;\n  border-top-color: #d3d9db;\n  border-radius: 0 0 0.25rem 0.25rem;\n}\n\n.axis-toggle__dropdown ul {\n  margin: 0;\n  padding: 0;\n  list-style: none;\n}\n\n.axis-toggle__dropdown li {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  width: 100%;\n}\n\n.axis-toggle__dropdown li:last-child {\n  border-bottom: solid 1px #a7b3b7;\n}\n\n.axis-toggle__dropdown li span {\n  padding: 0.3em;\n}\n\n.axis-toggle__dropdown li span.toggler-action {\n  border: none;\n  margin-top: -0.1em;\n}\n\n.axis-toggle__dropdown li span.toggler-action button i {\n  vertical-align: text-bottom;\n}\n\n.axis-input {\n  text-align: center;\n  width: 3em !important;\n  padding: 0.3rem 0.3rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
