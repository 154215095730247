// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.correlations-filters .Select__control {
  height: calc(1.51562rem + 2px);
}
.correlations-filters .Select__placeholder {
  height: calc(1.91562rem);
}

.CorrDragHandle {
  z-index: 2;
  cursor: row-resize;
  color: #0085ff;
  right: 0;
  position: absolute;
}
.CorrDragHandle:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.CorrDragHandleActive,
.CorrDragHandleActive:hover {
  color: #0b6fcc;
  z-index: 3;
}

.CorrDragHandleIcon {
  justify-content: center;
  align-items: center;
  line-height: 0.25;
  width: 100%;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./static/popups/correlations/CorrelationsGrid.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;AAChC;AACA;EACE,wBAAwB;AAC1B;;AAEA;EACE,UAAU;EACV,kBAAkB;EAClB,cAAc;EACd,QAAQ;EACR,kBAAkB;AACpB;AACA;EACE,oCAAoC;AACtC;;AAEA;;EAEE,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,WAAW;EACX,kBAAkB;AACpB","sourcesContent":[".correlations-filters .Select__control {\n  height: calc(1.51562rem + 2px);\n}\n.correlations-filters .Select__placeholder {\n  height: calc(1.91562rem);\n}\n\n.CorrDragHandle {\n  z-index: 2;\n  cursor: row-resize;\n  color: #0085ff;\n  right: 0;\n  position: absolute;\n}\n.CorrDragHandle:hover {\n  background-color: rgba(0, 0, 0, 0.1);\n}\n\n.CorrDragHandleActive,\n.CorrDragHandleActive:hover {\n  color: #0b6fcc;\n  z-index: 3;\n}\n\n.CorrDragHandleIcon {\n  justify-content: center;\n  align-items: center;\n  line-height: 0.25;\n  width: 100%;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
