// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.instances .cell,
.instances .headerCell {
  width: 100%;
  height: 100%;
  display: inherit;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(170, 170, 170, 0.25);
}

.instances .headerCell {
  font-weight: bold;
}

.instances .clickable:hover {
  font-weight: bold;
  cursor: pointer;
  background-color: rgba(82, 187, 239, 0.2);
}
.instances .active {
  font-weight: bold;
  background-color: rgba(0, 255, 0, 0.2);
}
`, "",{"version":3,"sources":["webpack://./static/popups/instances/Instances.css"],"names":[],"mappings":"AAAA;;EAEE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,uCAAuC;AACzC;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,yCAAyC;AAC3C;AACA;EACE,iBAAiB;EACjB,sCAAsC;AACxC","sourcesContent":[".instances .cell,\n.instances .headerCell {\n  width: 100%;\n  height: 100%;\n  display: inherit;\n  border-width: 1px;\n  border-style: solid;\n  border-color: rgba(170, 170, 170, 0.25);\n}\n\n.instances .headerCell {\n  font-weight: bold;\n}\n\n.instances .clickable:hover {\n  font-weight: bold;\n  cursor: pointer;\n  background-color: rgba(82, 187, 239, 0.2);\n}\n.instances .active {\n  font-weight: bold;\n  background-color: rgba(0, 255, 0, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
