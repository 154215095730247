// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input.column-analysis-filter {
  padding: 0.45rem;
}

.ordinal-dd .Select {
  min-width: 10em;
}

.ordinal-dd.cleaner-dd .Select {
  width: 100%;
}

.ordinal-dd .is-clearable.Select--single .Select__value-container--has-value > .Select__single-value {
  padding-right: 0;
}
`, "",{"version":3,"sources":["webpack://./static/popups/analysis/ColumnAnalysis.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["input.column-analysis-filter {\n  padding: 0.45rem;\n}\n\n.ordinal-dd .Select {\n  min-width: 10em;\n}\n\n.ordinal-dd.cleaner-dd .Select {\n  width: 100%;\n}\n\n.ordinal-dd .is-clearable.Select--single .Select__value-container--has-value > .Select__single-value {\n  padding-right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
