// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-frame:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.image-frame img {
  vertical-align: middle;
  max-width: 100%;
  min-width: 100%;
}

.missingno-inputs .btn-group.compact {
  padding-left: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./static/dtale/side/MissingNoCharts.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,qBAAqB;EACrB,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".image-frame:before {\n  content: '';\n  display: inline-block;\n  height: 100%;\n  vertical-align: middle;\n}\n\n.image-frame img {\n  vertical-align: middle;\n  max-width: 100%;\n  min-width: 100%;\n}\n\n.missingno-inputs .btn-group.compact {\n  padding-left: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
