// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hoverable.low-variance .hoverable__content {
  width: 25em;
}
.column-toggle__dropdown ul.low-variance-conditions {
  list-style: disc;
  margin: 0.75em;
  font-size: 85%;
}

ul.low-variance-conditions li {
  display: list-item;
  border-bottom-width: 0;
}
`, "",{"version":3,"sources":["webpack://./static/dtale/menu/LowVarianceOption.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;AACxB","sourcesContent":[".hoverable.low-variance .hoverable__content {\n  width: 25em;\n}\n.column-toggle__dropdown ul.low-variance-conditions {\n  list-style: disc;\n  margin: 0.75em;\n  font-size: 85%;\n}\n\nul.low-variance-conditions li {\n  display: list-item;\n  border-bottom-width: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
