// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.predefined-filter-input {
  border: solid 1px lightgray;
  border-radius: 0.5em;
}

.predefined-filter-input ul {
  padding-left: 1em;
}

.predefined-filter-input input {
  width: 100%;
}

.predefined-filter-errors {
  font-color: red;
  list-style: disc;
}
`, "",{"version":3,"sources":["webpack://./static/dtale/side/predefined_filters/Panel.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".predefined-filter-input {\n  border: solid 1px lightgray;\n  border-radius: 0.5em;\n}\n\n.predefined-filter-input ul {\n  padding-left: 1em;\n}\n\n.predefined-filter-input input {\n  width: 100%;\n}\n\n.predefined-filter-errors {\n  font-color: red;\n  list-style: disc;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
