// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gage-report .cell,
.gage-report .headerCell {
  width: 100%;
  height: 100%;
  display: inline-block;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(170, 170, 170, 0.25);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.gage-report .headerCell {
  font-weight: bold;
}

.gage-report-div {
  height: 200px;
}
`, "",{"version":3,"sources":["webpack://./static/dtale/side/GageRnR.css"],"names":[],"mappings":"AAAA;;EAEE,WAAW;EACX,YAAY;EACZ,qBAAqB;EACrB,iBAAiB;EACjB,mBAAmB;EACnB,uCAAuC;EACvC,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".gage-report .cell,\n.gage-report .headerCell {\n  width: 100%;\n  height: 100%;\n  display: inline-block;\n  border-width: 1px;\n  border-style: solid;\n  border-color: rgba(170, 170, 170, 0.25);\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.gage-report .headerCell {\n  font-weight: bold;\n}\n\n.gage-report-div {\n  height: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
