// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dtypes .cell,
.dtypes .headerCell {
  width: 100%;
  height: 100%;
  display: inherit;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(170, 170, 170, 0.25);
}
.dtypes .headerCell {
  font-weight: bold;
  cursor: pointer;
}
.dtypes .headerCell.filterable .row {
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 0.5em;
  padding-top: 0.5em;
}
.dtypes .headerCell.filterable .col,
.dtypes .headerCell.filterable .col-auto {
  padding-left: 0;
  padding-right: 0;
}

.dtypes .dtype-row-selected {
  font-weight: bold;
  background-color: rgba(82, 187, 239, 0.2);
}
.dtypes .dtype-row:hover {
  font-weight: bold;
  cursor: pointer;
  background-color: rgba(82, 187, 239, 0.2);
}
`, "",{"version":3,"sources":["webpack://./static/popups/describe/DtypesGrid.css"],"names":[],"mappings":"AAAA;;EAEE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,uCAAuC;AACzC;AACA;EACE,iBAAiB;EACjB,eAAe;AACjB;AACA;EACE,cAAc;EACd,eAAe;EACf,qBAAqB;EACrB,kBAAkB;AACpB;AACA;;EAEE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,yCAAyC;AAC3C;AACA;EACE,iBAAiB;EACjB,eAAe;EACf,yCAAyC;AAC3C","sourcesContent":[".dtypes .cell,\n.dtypes .headerCell {\n  width: 100%;\n  height: 100%;\n  display: inherit;\n  border-width: 1px;\n  border-style: solid;\n  border-color: rgba(170, 170, 170, 0.25);\n}\n.dtypes .headerCell {\n  font-weight: bold;\n  cursor: pointer;\n}\n.dtypes .headerCell.filterable .row {\n  margin-left: 0;\n  margin-right: 0;\n  padding-bottom: 0.5em;\n  padding-top: 0.5em;\n}\n.dtypes .headerCell.filterable .col,\n.dtypes .headerCell.filterable .col-auto {\n  padding-left: 0;\n  padding-right: 0;\n}\n\n.dtypes .dtype-row-selected {\n  font-weight: bold;\n  background-color: rgba(82, 187, 239, 0.2);\n}\n.dtypes .dtype-row:hover {\n  font-weight: bold;\n  cursor: pointer;\n  background-color: rgba(82, 187, 239, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
