// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-footer.confirmation {
  justify-content: center;
}

.modal-footer.confirmation > .btn {
  min-width: 8em;
  border: 1px solid transparent;
}

.modal-footer.confirmation > :not(:last-child) {
  margin-right: unset;
}
`, "",{"version":3,"sources":["webpack://./static/popups/Confirmation.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,6BAA6B;AAC/B;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".modal-footer.confirmation {\n  justify-content: center;\n}\n\n.modal-footer.confirmation > .btn {\n  min-width: 8em;\n  border: 1px solid transparent;\n}\n\n.modal-footer.confirmation > :not(:last-child) {\n  margin-right: unset;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
