// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-group .form-control.slider-input {
  width: 2em;
  border-radius: 0.25rem !important;
  padding: 0.25em;
  text-align: center;
}

.input-group .form-control.alpha-slider-input {
  width: 3.75em;
  border-radius: 0.25rem !important;
  padding: 0.25em;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./static/popups/create/CreateWinsorize.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,iCAAiC;EACjC,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,iCAAiC;EACjC,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".input-group .form-control.slider-input {\n  width: 2em;\n  border-radius: 0.25rem !important;\n  padding: 0.25em;\n  text-align: center;\n}\n\n.input-group .form-control.alpha-slider-input {\n  width: 3.75em;\n  border-radius: 0.25rem !important;\n  padding: 0.25em;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
