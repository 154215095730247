// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input.numeric-input::-webkit-outer-spin-button,
input.numeric-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'].numeric-input {
  -moz-appearance: textfield;
}

.btn-group.agg-types .btn {
  white-space: normal;
  padding: 0.35rem 0.3rem;
  font-size: 10px;
}

div.col-agg-col-input div.Select {
  width: 13em;
  min-width: 13em;
}

div.col-agg-col-input div.is-clearable.Select--single .Select__value-container--has-value > .Select__single-value {
  padding-right: 5px;
}
`, "",{"version":3,"sources":["webpack://./static/popups/reshape/Reshape.css"],"names":[],"mappings":"AAAA;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA,YAAY;AACZ;EACE,0BAA0B;AAC5B;;AAEA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["input.numeric-input::-webkit-outer-spin-button,\ninput.numeric-input::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n/* Firefox */\ninput[type='number'].numeric-input {\n  -moz-appearance: textfield;\n}\n\n.btn-group.agg-types .btn {\n  white-space: normal;\n  padding: 0.35rem 0.3rem;\n  font-size: 10px;\n}\n\ndiv.col-agg-col-input div.Select {\n  width: 13em;\n  min-width: 13em;\n}\n\ndiv.col-agg-col-input div.is-clearable.Select--single .Select__value-container--has-value > .Select__single-value {\n  padding-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
