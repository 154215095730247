// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.container-fluid.code-popup .code-popup-modal pre,
div.container-fluid.code-export .code-popup-modal pre {
  height: calc(100vh - 100px) !important;
}
div.container-fluid.code-popup .code-popup-modal {
  padding-top: 45px;
}
.code-popup-modal pre {
  height: 350px;
}
`, "",{"version":3,"sources":["webpack://./static/popups/CodePopup.css"],"names":[],"mappings":"AAAA;;EAEE,sCAAsC;AACxC;AACA;EACE,iBAAiB;AACnB;AACA;EACE,aAAa;AACf","sourcesContent":["div.container-fluid.code-popup .code-popup-modal pre,\ndiv.container-fluid.code-export .code-popup-modal pre {\n  height: calc(100vh - 100px) !important;\n}\ndiv.container-fluid.code-popup .code-popup-modal {\n  padding-top: 45px;\n}\n.code-popup-modal pre {\n  height: 350px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
