// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.frequency-header {
  font-weight: bold;
  text-align: center;
}

.frequency-row {
}

.frequency-row-grey {
  background-color: rgba(82, 187, 239, 0.2);
}

.frequency-row-bold {
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./static/popups/describe/FrequencyGrid.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;AACA;;AAEA;EACE,yCAAyC;AAC3C;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".frequency-header {\n  font-weight: bold;\n  text-align: center;\n}\n\n.frequency-row {\n}\n\n.frequency-row-grey {\n  background-color: rgba(82, 187, 239, 0.2);\n}\n\n.frequency-row-bold {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
